var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "subtitle-1 font-weight-bold mb-2" }, [
      _vm._v("Connecting to:"),
    ]),
    _c(
      "div",
      { staticClass: "d-flex align-start mb-2 flex-wrap" },
      [
        _c("mew-select", {
          staticClass: "mr-0 mr-sm-4 network-selection",
          attrs: {
            value: _vm.ledgerApp,
            items: _vm.ledgerApps,
            "is-custom": true,
          },
          on: { input: _vm.handleApp },
        }),
        _c("access-wallet-derivation-path", {
          staticClass: "derivation-path ml-0 ml-sm-auto",
          attrs: {
            "selected-path": _vm.selectedPath,
            "passed-paths": _vm.paths,
            "disable-custom-paths": true,
            "is-mobile": _vm.isMobile,
          },
          on: { setPath: _vm.setPath },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "sheet d-flex align-center justify-center" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-center justify-center pb-8 pt-15 pt-md-18 flex-wrap",
        },
        [
          _vm._m(0),
          _c("v-img", {
            staticClass: "mb-10 ml-10",
            attrs: {
              src: require("@/assets/images/hardware-wallets/Ledger_Device_main.svg"),
              alt: "Ledger Wallet",
              "max-width": "21em",
              "max-height": "10em",
              contain: "",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "d-flex justify-center text-center mt-5 flex-wrap",
        class: [!_vm.isMobile ? "justify-space-between" : ""],
      },
      [
        _c(
          "div",
          { staticClass: "section-block", on: { click: _vm.ledgerUnlockBle } },
          [
            _c("v-img", {
              staticClass: "connect-img",
              attrs: {
                src: require("@/assets/images/hardware-wallets/Bluetooth.svg"),
                alt: "Bluetooth",
                "max-width": "50px",
                "max-height": "50px",
              },
            }),
            _c("div", { staticClass: "buttonTitle mew-heading-3 mt-8" }, [
              _vm._v("Connect via Bluetooth"),
            ]),
            _c("div", { staticClass: "mew-label mb-10 label" }, [
              _vm._v("Ledger Nano X Only"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "section-block", on: { click: _vm.ledgerUnlock } },
          [
            _c("v-img", {
              staticClass: "connect-img",
              attrs: {
                src: require("@/assets/images/hardware-wallets/USB.svg"),
                alt: "USB",
                "max-width": "50px",
                "max-height": "50px",
              },
            }),
            _c("div", { staticClass: "buttonTitle mew-heading-3 mt-8" }, [
              _vm._v("Connect via USB"),
            ]),
            _c("div", { staticClass: "mew-label mb-10 label" }, [
              _vm._v("All Ledgers"),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      [
        _c("mew-alert", {
          staticClass: "mt-5",
          attrs: {
            title: "Device not showing when pairing on Google Chrome?",
            description: "You may have to allow backend permissions.",
            theme: "warning",
            "hide-close-icon": "",
            "link-object": _vm.article,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "para" }, [
      _vm._v(" Choose "),
      _c("b", [_vm._v("Ethereum")]),
      _vm._v(" on your device and connect with one of the methods below. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }